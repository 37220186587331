@import "constants";

#roadmap-link-to {
  position: relative;
  top: 100px;
}

#roadmap-cont {
  position: relative;
}

#roadmap-bg {
  position: absolute;
  background-color: $black;
  width: 100%;
  height: calc(100% + 130px);
  top: 50px;
  overflow: hidden;
  user-select: none;

  .roadmap-bg-img-cont {
    position: absolute;
    height: 1032px;

    &:nth-child(1) {
      top: 80px;
      left: -20vw;
    }

    &:nth-child(2) {
      top: 1182px;
      right: -20vw;
    }
  }
}

#roadmap-section {
  margin-top: 200px;
  color: white;
}

#roadmap-title {
  font-family: $header-font;
  font-size: 80px;
  line-height: 100px;
}

#roadmap-desc {
  font-family: $body-font;
  font-size: 24px;
  line-height: 30px;
}

#roadmap-list {
  position: relative;
  margin-top: 100px;
  padding: 0 156px;

  &::after {
    display: block;
    content: " ";
    width: 2px;
    height: calc(100% + 325px);
    background-color: $gold;
    position: absolute;
    top: -45px;
    left: 180px;
  }

  .roadmap-quarter-row,
  .roadmap-date-row {
    display: flex;

    .indicator-cont {
      position: relative;
      width: 50px;
      flex-shrink: 0;
      margin-right: 36px;
      z-index: 1;

      &::after {
        display: block;
        content: " ";
        border: 3px solid $gold;
        border-radius: 100px;
        position: absolute;
        left: 50%;
        background-color: $black;
      }

      &.active-indicator::after {
        background-color: $gold;
      }
    }

    h3,
    h4 {
      text-transform: uppercase;
    }
  }

  .roadmap-quarter-row {
    align-items: center;
    margin: 32px auto;

    .indicator-cont::after {
      width: 50px;
      height: 50px;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    h3 {
      font-size: 36px;
      line-height: 45px;
    }
  }

  .roadmap-date-row {
    align-items: flex-start;
    margin: 30px auto;

    .indicator-cont::after {
      width: 25px;
      height: 25px;
      top: 4px;
      transform: translateX(-50%);
    }

    h4 {
      font-size: 24px;
      line-height: 30px;
    }

    p {
      margin-top: 6px;
      font-size: 20px;
      line-height: 24px;
    }
  }
}

@media (max-width: $mobile-screen-width) {
  #roadmap-link-to {
    top: -20px;
  }

  #roadmap-bg {
    top: 30px;
    height: calc(100% + 30px);

    .roadmap-bg-img-cont {
      display: none;
    }
  }

  #roadmap-section {
    margin-top: 80px;
  }

  #roadmap-title,
  #roadmap-desc {
    margin-left: 22px;
  }

  #roadmap-title {
    font-size: 48px;
    line-height: 60px;
  }

  #roadmap-desc {
    font-size: 18px;
    line-height: 22px;
  }

  #roadmap-list {
    padding-left: 38px;
    padding-right: 20px;
    margin-top: 70px;

    &::after {
      left: 47px;
      height: calc(100% + 160px);
    }

    .roadmap-quarter-row,
    .roadmap-date-row {
      .indicator-cont {
        width: 20px;
        height: 20px;
        margin-right: 26px;

        &::after {
          border: 2px solid $gold;
        }
      }
    }

    .roadmap-quarter-row {
      margin: 30px auto 16px;

      .indicator-cont::after {
        width: 22px;
        height: 22px;
      }

      h3 {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .roadmap-date-row {
      margin: 24px auto;

      .indicator-cont::after {
        width: 12px;
        height: 12px;
      }

      h4 {
        font-size: 18px;
        line-height: 22px;
      }

      p {
        margin-top: 0;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
