#roadmap-link-to {
  position: relative;
  top: 100px;
}

#roadmap-cont {
  position: relative;
}

#roadmap-bg {
  width: 100%;
  height: calc(100% + 130px);
  user-select: none;
  background-color: #000406;
  position: absolute;
  top: 50px;
  overflow: hidden;
}

#roadmap-bg .roadmap-bg-img-cont {
  height: 1032px;
  position: absolute;
}

#roadmap-bg .roadmap-bg-img-cont:nth-child(1) {
  top: 80px;
  left: -20vw;
}

#roadmap-bg .roadmap-bg-img-cont:nth-child(2) {
  top: 1182px;
  right: -20vw;
}

#roadmap-section {
  color: #fff;
  margin-top: 200px;
}

#roadmap-title {
  font-family: Righteous, sans-serif;
  font-size: 80px;
  line-height: 100px;
}

#roadmap-desc {
  font-family: Oxygen, sans-serif;
  font-size: 24px;
  line-height: 30px;
}

#roadmap-list {
  margin-top: 100px;
  padding: 0 156px;
  position: relative;
}

#roadmap-list:after {
  content: " ";
  width: 2px;
  height: calc(100% + 325px);
  background-color: #9e7509;
  display: block;
  position: absolute;
  top: -45px;
  left: 180px;
}

#roadmap-list .roadmap-quarter-row, #roadmap-list .roadmap-date-row {
  display: flex;
}

#roadmap-list .roadmap-quarter-row .indicator-cont, #roadmap-list .roadmap-date-row .indicator-cont {
  width: 50px;
  z-index: 1;
  flex-shrink: 0;
  margin-right: 36px;
  position: relative;
}

#roadmap-list .roadmap-quarter-row .indicator-cont:after, #roadmap-list .roadmap-date-row .indicator-cont:after {
  content: " ";
  background-color: #000406;
  border: 3px solid #9e7509;
  border-radius: 100px;
  display: block;
  position: absolute;
  left: 50%;
}

#roadmap-list .roadmap-quarter-row .indicator-cont.active-indicator:after, #roadmap-list .roadmap-date-row .indicator-cont.active-indicator:after {
  background-color: #9e7509;
}

#roadmap-list .roadmap-quarter-row h3, #roadmap-list .roadmap-quarter-row h4, #roadmap-list .roadmap-date-row h3, #roadmap-list .roadmap-date-row h4 {
  text-transform: uppercase;
}

#roadmap-list .roadmap-quarter-row {
  align-items: center;
  margin: 32px auto;
}

#roadmap-list .roadmap-quarter-row .indicator-cont:after {
  width: 50px;
  height: 50px;
  top: 50%;
  transform: translate(-50%, -50%);
}

#roadmap-list .roadmap-quarter-row h3 {
  font-size: 36px;
  line-height: 45px;
}

#roadmap-list .roadmap-date-row {
  align-items: flex-start;
  margin: 30px auto;
}

#roadmap-list .roadmap-date-row .indicator-cont:after {
  width: 25px;
  height: 25px;
  top: 4px;
  transform: translateX(-50%);
}

#roadmap-list .roadmap-date-row h4 {
  font-size: 24px;
  line-height: 30px;
}

#roadmap-list .roadmap-date-row p {
  margin-top: 6px;
  font-size: 20px;
  line-height: 24px;
}

@media (max-width: 1140px) {
  #roadmap-link-to {
    top: -20px;
  }

  #roadmap-bg {
    height: calc(100% + 30px);
    top: 30px;
  }

  #roadmap-bg .roadmap-bg-img-cont {
    display: none;
  }

  #roadmap-section {
    margin-top: 80px;
  }

  #roadmap-title, #roadmap-desc {
    margin-left: 22px;
  }

  #roadmap-title {
    font-size: 48px;
    line-height: 60px;
  }

  #roadmap-desc {
    font-size: 18px;
    line-height: 22px;
  }

  #roadmap-list {
    margin-top: 70px;
    padding-left: 38px;
    padding-right: 20px;
  }

  #roadmap-list:after {
    height: calc(100% + 160px);
    left: 47px;
  }

  #roadmap-list .roadmap-quarter-row .indicator-cont, #roadmap-list .roadmap-date-row .indicator-cont {
    width: 20px;
    height: 20px;
    margin-right: 26px;
  }

  #roadmap-list .roadmap-quarter-row .indicator-cont:after, #roadmap-list .roadmap-date-row .indicator-cont:after {
    border: 2px solid #9e7509;
  }

  #roadmap-list .roadmap-quarter-row {
    margin: 30px auto 16px;
  }

  #roadmap-list .roadmap-quarter-row .indicator-cont:after {
    width: 22px;
    height: 22px;
  }

  #roadmap-list .roadmap-quarter-row h3 {
    font-size: 24px;
    line-height: 30px;
  }

  #roadmap-list .roadmap-date-row {
    margin: 24px auto;
  }

  #roadmap-list .roadmap-date-row .indicator-cont:after {
    width: 12px;
    height: 12px;
  }

  #roadmap-list .roadmap-date-row h4 {
    font-size: 18px;
    line-height: 22px;
  }

  #roadmap-list .roadmap-date-row p {
    margin-top: 0;
    font-size: 14px;
    line-height: 18px;
  }
}

/*# sourceMappingURL=index.3557223f.css.map */
